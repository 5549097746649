<script>
import BaseHeading from '@/components/base-heading'
import NewTweet from '@/components/tweet/new-tweet'
import BaseTrip from '@/components/tweet/base-trip'
import {mapState, mapActions} from 'vuex'
import {sortByDate} from '../../helper/functions'
import axios from 'axios'
import SidebarSearch from '@/components/sidebar-right/search.vue'

export default {
   name: 'HomeView',
   components: {
      BaseHeading,
      // BaseText,
      NewTweet,
      // BaseTweet,
      // StatsView,
      // BaseDropdown,
      // InlineSvg,
      BaseTrip,
      SidebarSearch,
   },
   data() {
      return {
         loggedUserHandle: JSON.parse(localStorage.getItem('loggedUserHandle')),
         tweetArray: [],
         tripArray: [],
         loadingTrips: true,
         error: null,
      }
   },
   computed: {
      ...mapState(['loggedUser']),
      ...mapState({
         searchedUser: (state) => state.searchedUser,
      }),
      allTweets() {
         const user = this.searchedUser?.following?.map((user) => user) ?? []
         let tweets = []
         for (let x in user) {
            if (user[x]?.tweets) {
               tweets.push(...user[x].tweets)
            }
         }
         return tweets
            ? [...tweets, ...this.searchedUser?.tweets].sort(sortByDate)
            : [...this.searchedUser?.tweets].sort(sortByDate)
      },
      allTrips() {
         const user = this.searchedUser?.following?.map((user) => user) ?? []
         let trips = []
         for (let x in user) {
            if (user[x]?.trips) {
               trips.push(...user[x].trips)
            }
         }
         return trips
            ? [...trips, ...this.searchedUser?.trips].sort(sortByDate)
            : [...this.searchedUser?.trips].sort(sortByDate)
      },
   },
   methods: {
      ...mapActions(['fetchUser']),
      async loadAllTweets() {
         try {
            const response = await axios.get('/tweets')
            this.tweetArray = response.data
         } catch (e) {
            this.error = e.response.data.message
         }
      },
      async loadAllTrips() {
         try {
            this.loadingTrips = true
            const response = await axios.get('/trips')
            this.tripArray = response.data
            // for (let x in response.data) {
            //    this.tripArray.push(response.data[x])
            // }
            this.loadingTrips = false
            // return this.tripArray
         } catch (e) {
            this.error = e.response.data.message
            this.loadingTrips = false
         }
      },
      async getUser(handle) {
         try {
            await this.fetchUser(handle)
         } catch (e) {
            this.error = e.response.data.message
         }
      },
   },
   async mounted() {
      // Here we'll use the `nextTick` to ensure the DOM has been updated before we potentially manipulate it
      this.$nextTick(async () => {
         // Your original logic to check if the user is logged in or not
         if (!this.loggedUser) {
            await this.loadAllTweets()
            await this.loadAllTrips()
         }
         // This logic ensures that we always fetch the user based on the URL or local storage
         const handle = this.$route.params.handle || this.loggedUserHandle
         if (handle) {
            await this.getUser(handle)
         }
      })
   },
   watch: {
      // Watch for changes on `loggedUser` to reactively fetch data when the user logs in
      loggedUser: {
         immediate: true,
         handler: async function (newVal, oldVal) {
            if (newVal && !oldVal) {
               await this.loadAllTweets()
               await this.loadAllTrips()
            }
         },
      },
   },
}
//    async created() {
//       if (this.loggedUser) {
//          // window.location.reload()
//       } else {
//          await this.loadAllTweets()
//          await this.loadAllTrips()
//          console.log(this.tripArray, 'tripArray123')
//          console.log(this.tweetArray, 'tweetArray123')
//          const handle = this.$route.params.handle || this.loggedUserHandle
//          await this.getUser(handle)
//       }
//    },
// }
</script>

<template>
   <div class="container">
      <div class="ab">
         <BaseHeading
            :title="`Hello ${loggedUserHandle}!`"
            v-if="loggedUser"
            class="name_head header-container text-red-600" />
         <BaseHeading
            title="Hello Eco Warrior"
            v-if="!loggedUser"
            class="text-red-600 name_head header-container" />
         <SidebarSearch
            placeholder="Search for your favorite influencer"
            class="search-css header-container w-1/2" />
         <div class="scroll">
            <!-- StatsView -->
         </div>
      </div>
      <div>
         <a href="https://www.germany.travel/en/home.html" target="_blank" class="block">
            <img
               src="@/assets/icons/dshum.png"
               alt="Image Description"
               class="dashh w-full cursor-pointer transition-opacity duration-300 hover:opacity-80"
         /></a>
         <NewTweet />
         <a href="https://www.germany.travel/en/home.html" target="_blank" class="block">
            <img
               src="@/assets/icons/dashdown.svg"
               alt="Image Description"
               class="dashh w-full cursor-pointer" />
         </a>
         <div v-if="loadingTrips" class="loader-container">
            <div class="lds-ellipsis">
               <div></div>
               <div></div>
               <div></div>
               <div></div>
            </div>
            <span>New Trips Incoming...</span>
         </div>
         <div v-for="trip in tripArray" :key="trip._id">
            <BaseTrip
               :id="trip._id"
               :author="trip.author"
               :createdAt="trip.createdAt"
               :content="trip.content"
               :description="trip.description"
               :karma="trip.karma"
               :city_from="trip.city_from"
               :city_to="trip.city_to"
               :days="trip.days"
               :likesTrip="trip.likesTrip.length" />
         </div>
         <!-- BaseTweet -->
         <!-- Uncomment this section if you want to display BaseTweet components -->
         <!--
         <div v-for="(tweet, index) in tweetArray" :key="tweet._id">
            <BaseTweet
               :id="tweet._id"
               :author="tweet.author"
               :createdAt="tweet.createdAt"
               :content="tweet.content"
               :attachment="tweet.attachment"
               :replies="tweet.replies.length"
               :retweets="tweet.retweets.length"
               :likes="tweet.likes.length"
            />
         </div> -->
      </div>
   </div>
</template>

<style lang="postcss" scoped>
.lds-ellipsis {
   display: inline-block;
   position: relative;
   width: 80px;
   height: 80px;
}
.lds-ellipsis div {
   position: absolute;
   top: 33px;
   width: 13px;
   height: 13px;
   border-radius: 50%;
   background: #d1011b;
   animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
   left: 8px;
   animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
   left: 8px;
   animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
   left: 32px;
   animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
   left: 56px;
   animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
   0% {
      transform: scale(0);
   }
   100% {
      transform: scale(1);
   }
}

@keyframes lds-ellipsis3 {
   0% {
      transform: scale(1);
   }
   100% {
      transform: scale(0);
   }
}

@keyframes lds-ellipsis2 {
   0% {
      transform: translate(0, 0);
   }
   100% {
      transform: translate(24px, 0);
   }
}
.loader-container {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px;
   font-size: 1.5rem;
   color: #555;
   /* The following lines are added for the animated loader */
   position: relative; /* Position relative to align pseudo-elements */
}
.search-css {
   width: 50%;
}
.ab {
   position: sticky;
   top: 0;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 10px 20px; /* Adjust padding as needed */
   background-color: #f4f3ee;
}
.name_head {
   color: #d1011b;
}
.scroll {
   max-height: calc(100vh - 130px); /* Adjust the height as needed */
   overflow-y: auto;
   padding-top: 20px;
   &::-webkit-scrollbar {
      display: none;
   }
}
</style>
