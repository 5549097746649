import {createStore} from 'vuex'
import axios from 'axios'

axios.defaults.baseURL = 'https://slow-summer-api.citruz.in/'
axios.defaults.withCredentials = true

const Mutations = {
   SET_USER: 'SET_USER',
   SET_SEARCHED_USER: 'SET_SEARCHED_USER',
   NEW_TWEET: 'NEW_TWEET',
   NEW_TRIP: 'NEW_TRIP',
   REMOVE_TRIP: 'REMOVE_TRIP',
   UPDATE_TRIP_MODAL_STATE: 'UPDATE_TRIP_MODAL_STATE',
   REMOVE_TWEET: 'REMOVE_TWEET',
   UPDATE_TWEET_MODAL_STATE: 'UPDATE_TWEET_MODAL_STATE',
   SET_FOLLOWED_USERS: 'SET_FOLLOWED_USERS',
   SET_ALL_USERS: 'SET_ALL_USERS',
   SET_NON_FOLLOWED_USERS: 'SET_NON_FOLLOWED_USERS',
}

const initPlugin = (store) => {
   store.dispatch('initialize')
}

export default createStore({
   state: {
      loggedUser: null,
      searchedUser: null,
      isTweetModalOpen: false,
      isTripModalOpen: false,
      followedUsers: [],
      allUsers: [],
      nonFollowedUsers: [],
   },
   mutations: {
      [Mutations.SET_USER](state, user) {
         state.loggedUser = user
      },
      [Mutations.SET_SEARCHED_USER](state, user) {
         state.searchedUser = user
      },
      [Mutations.NEW_TWEET](state, tweet) {
         if (state.loggedUser._id != state.searchedUser._id) return
         state.searchedUser.tweets.push(tweet)
      },
      [Mutations.REMOVE_TWEET](state, id) {
         const index = state.searchedUser.tweets.findIndex((t) => t._id == id)
         state.searchedUser.tweets.splice(index, 1)
      },
      [Mutations.UPDATE_TWEET_MODAL_STATE](state, val) {
         state.isTweetModalOpen = val
      },
      [Mutations.NEW_TRIP](state, trip) {
         if (state.loggedUser._id != state.searchedUser._id) return
         state.searchedUser.trips.push(trip)
      },
      [Mutations.REMOVE_TRIP](state, id) {
         const index = state.searchedUser.trips.findIndex((t) => t._id == id)
         state.searchedUser.trips.splice(index, 1)
      },
      [Mutations.UPDATE_TRIP_MODAL_STATE](state, val) {
         state.isTripModalOpen = val
      },
      [Mutations.SET_FOLLOWED_USERS](state, user) {
         state.followedUsers = user
      },
      [Mutations.SET_ALL_USERS](state, users) {
         state.allUsers = users
      },
      [Mutations.SET_NON_FOLLOWED_USERS](state, users) {
         state.nonFollowedUsers = users
      },
   },
   getters: {
      getHandle(state) {
         if (!state.loggedUser) {
            return
         }
         return state.loggedUser.handle
      },
      getFollowedUsers(state) {
         return state.followedUsers
      },
      getAllUsers(state) {
         return state.allUsers
      },
      getNonFollowedUsers(state) {
         return state.nonFollowedUsers
      },
   },
   actions: {
      async initialize({dispatch}) {
         // console.log(JSON.parse(localStorage.getItem('isLoggedIn')))
         if (JSON.parse(localStorage.getItem('isLoggedIn'))) {
            await dispatch('fetchSession')
         }
      },

      async fetchSession({commit}) {
         try {
            const user = await axios.get('/users/login')
            if (!user) return
            commit(Mutations.SET_USER, user.data)
            return user
         } catch (e) {
            console.log(e.response.data.message)
         }
      },

      async authenticate({commit}, payload) {
         const user = await axios.post('/users/login', payload)
         if (!user) return
         commit(Mutations.SET_USER, user.data)
         localStorage.setItem('isLoggedIn', JSON.stringify(true))
         localStorage.setItem('loggedUserHandle', JSON.stringify(user.data.handle))
         localStorage.setItem('loggedUserId', JSON.stringify(user.data._id))
      },

      // logout
      async logout({commit}) {
         await axios.get('/users/logout')
         commit(Mutations.SET_USER, null)
         localStorage.setItem('isLoggedIn', JSON.stringify(false))
         localStorage.setItem('loggedUserHandle', JSON.stringify(null))
         localStorage.setItem('loggedUserId', JSON.stringify(null))
      },

      // resigter
      async register(ctx, payload) {
         return await axios.post('/users/register', payload)
      },

      // follow a user
      async follow(ctx, id) {
         return await axios.get(`/users/${id}/follow`)
      },

      // unfollow a user
      async unfollow(ctx, id) {
         return await axios.get(`/users/${id}/unfollow`)
      },

      // like a tweet
      async like({dispatch}, id) {
         await axios.patch(`/tweets/${id}/like`)
         await dispatch('fetchSession')
      },
      // like a trip
      async likeTrip({dispatch}, id) {
         await axios.patch(`/trips/${id}/like`)
         await dispatch('fetchSession')
      },

      // unlike a tweet
      async unlike({dispatch}, id) {
         await axios.patch(`/tweets/${id}/unlike`)
         await dispatch('fetchSession')
      },
      // unlike a trip
      async unlikeTrip({dispatch}, id) {
         await axios.patch(`/trips/${id}/unlike`)
         await dispatch('fetchSession')
      },

      // fetch a user
      async fetchUser({commit}, handle) {
         const user = await axios.get(`/users/${handle}`)
         if (!user) return
         commit('SET_SEARCHED_USER', user.data)
      },

      // fetch a tweet
      async fetchTweet(ctx, id) {
         const tweet = await axios.get(`/tweets/${id}`)
         if (!tweet) return
         return tweet
      },

      // fetch a trip
      async fetchTrip(ctx, id) {
         const trip = await axios.get(`/trips/${id}`)
         if (!trip) return
         return trip
      },

      //fetch getImage
      fetchImage(ctx, id) {
         const image = axios.get(`/tweets/${id}/images`)
         if (!image) return
         return image
      },

      //fetch getTripImage
      fetchTripImage(ctx, id) {
         const image = axios.get(`/trips/${id}/images`)
         if (!image) return
         return image
      },
      // fetch all tweets
      async fetchAllTweets() {
         const tweets = await axios.get('/tweets')
         if (!tweets) return
         return tweets
         // commit(Mutations.SET_ALL_TWEETS, tweets.data)
      },

      // fetch all trips
      async fetchAllTrips() {
         const trips = await axios.get('/trips')
         if (!trips) return
         return trips
      },

      async createTweet({commit, dispatch}, formData) {
         const tweet = await axios.post('/tweets/new', formData, {
            headers: {'Content-Type': 'multipart/form-data'},
         })
         if (!tweet) return
         commit(Mutations.NEW_TWEET, tweet.data)
         await dispatch('fetchTweet', tweet.data._id)
      },

      async createTrip({commit, dispatch}, formData) {
         const trip = await axios.post('/trips/new', formData, {
            headers: {'Content-Type': 'multipart/form-data'},
         })
         if (!trip) return
         commit(Mutations.NEW_TRIP, trip.data)
         await dispatch('fetchTrip', trip.data._id)
      },

      async fetchFollowedUsers({commit}, handle) {
         const users = await axios.get(`/users/${handle}/following`)
         if (!users) return
         commit(Mutations.SET_FOLLOWED_USERS, users.data)
      },

      // get non followed users for the logged user
      async fetchNonFollowedUsers({commit}, handle) {
         const users = await axios.get(`/users/${handle}/nonfollowing`)
         if (!users) return
         commit(Mutations.SET_NON_FOLLOWED_USERS, users.data)
      },

      // remove a tweet
      async removeTweet({commit}, id) {
         await axios.delete(`/tweets/${id}`)
         // reload the page to update the tweet list
         location.reload('/home')
         commit(Mutations.REMOVE_TWEET, id)
      },

      // remove a trip
      async removeTrip({commit}, id) {
         await axios.delete(`/trips/${id}`)
         // reload the page to update the trip list
         location.reload('/home')
         commit(Mutations.REMOVE_TRIP, id)
      },

      async reTweet({commit}, id) {
         const tweet = await axios.patch(`/tweets/${id}/retweet`)
         commit(Mutations.NEW_TWEET, tweet.data)
      },

      async reTrip({commit}, id) {
         const trip = await axios.patch(`/trips/${id}/retrip`)
         commit(Mutations.NEW_TRIP, trip.data)
      },

      async unRetweet({commit}, id) {
         await axios.patch(`/tweets/${id}/unretweet`)
         commit(Mutations.REMOVE_TWEET, id)
      },

      async unReTrip({commit}, id) {
         await axios.patch(`/trips/${id}/unretrip`)
         commit(Mutations.REMOVE_TRIP, id)
      },
      async fetchAllUsers({commit}) {
         try {
            const users = await axios.get('users/get/getall')
            commit(Mutations.SET_ALL_USERS, users.data)
         } catch (e) {
            console.log(e.response.data.message)
         }
      },
   },
   modules: {},
   plugins: [initPlugin],
})
