import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/styles/app.css'
import mixpanel from 'mixpanel-browser'
mixpanel.init('111fef86c71e133a5e0bd1edeac7c161', {
   debug: true,
   track_pageview: true,
   ignore_dnt: true,
})

createApp(App).use(store).use(router).mount('#app')
