<div v-if="isModalOpen" class="modal">
<div class="modal-body">
<div v-if="head" class="modal-head">
<div class="modal-head-left">
<div class="modal-close" @click="$emit('modal-close')">
<InlineSvg :src="require('@/assets/icons/times.svg')" width="16" height="16" />
</div>
<div class="modal-title">
<BaseText weight="fw-bold">{{ title }}</BaseText>
</div>
</div>
</div>
<div class="modal-content">
<form @submit.prevent="saveProfileChanges">
<div class="form-group">
<label for="name">Name</label>
<input id="name" type="text" v-model="newName" required />
</div>
<div class="form-group">
<label for="profileImage">Profile Image</label>
<input id="profileImage" type="file" @change="handleProfileImageChange" />
</div>
<div class="modal-button" v-if="button">
<BaseButton type="submit" :disabled="!newName && !newProfileImage">{{ buttonText }}</BaseButton>
</div>
</form>
</div>
</div>
<div class="overlay"></div>
</div>