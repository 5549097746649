<div class="followCard" v-if="loggedUser">
<div class="followCard-title">
<BaseText tag="span" weight="fw-bold" size="fs-large">Sustainable Influencers</BaseText>
</div>
<div v-for="user in randomUsers" :key="user.id" class="followCard-item">
<div class="avatar">
<BaseAvatar size="large" :src="user.profileImage"></BaseAvatar>
</div>
<div>
<div class="user">
<BaseText tag="a" weight="fw-bold" size="fs-medium" :href="`/${user.handle}`">{{ user.name }}</BaseText>
</div>
<div class="handle">
<BaseText class="hand" color="fw-red" tag="a" size="fs-medium">{{ '@' + user.handle }}</BaseText>
</div>
</div>
<BaseButton class="but" color="btn-dark" size="btn-small" tag="a" @click="followOrUnfollow(user._id, user.handle)">{{user.isFollowed ? 'Added' : 'Add'}}</BaseButton>
</div>
<div v-if="showModal" class="modal" @click="closeModal1">
<div class="modal-content">
<h2>You have followed an user</h2><br><button class="login-button" @click="$router.push('/influencers')">Visit Influencers Page</button>
</div>
</div>
</div>
<div class="followCard" v-else>
<div class="followCard-title">
<BaseText tag="span" weight="fw-bold" size="fs-large">Sustainable Influencers</BaseText>
</div>
<div v-for="user in users" :key="user.id" class="followCard-item">
<div class="avatar">
<BaseAvatar size="large" :src="user.profileImage"></BaseAvatar>
</div>
<div>
<div class="user">
<BaseText tag="a" weight="fw-bold" size="fs-medium" :href="`/${user.handle}`">{{ user.name }}</BaseText>
</div>
<div class="handle">
<BaseText color="#d1011b" tag="a" size="fs-medium">{{ '@' + user.handle }}</BaseText>
</div>
</div>
<BaseButton color="but" size="btn-small" tag="a" :href="user.profileLink" @click="followOrUnfollow(user._id, user.handle)">{{ isFollowed ? 'Added' : 'Add' }}</BaseButton>
</div>
<div v-if="showLoginModal" class="modal" @click="closeModal">
<div class="modal-content">
<h2>Please log in</h2>
<p>You need to log in to follow our top influencers.</p>
<button class="login-button" @click="$router.push('/login')">Log In</button>
</div>
</div>
</div>